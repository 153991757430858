import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  query login($data: login_input!) {
    login(data: $data) {
      status
      user_id
      session_id
      page_accesses
      page_component_accesses
      system_user {
        role_list {
          id
          name
        }
        username
        name
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.LoginState = {
  result: null,
  error: null,
  loading: false,
};

export const login_request = createAsyncThunk<
  TYPES.Login,
  { data: TYPES.LoginInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'system_user/login',
  async (params: { data: TYPES.LoginInput }, { extra: client }) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.login;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const login_slice = createSlice({
  name: 'login',
  initialState: initial_state,
  reducers: {
    login_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(login_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(login_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(login_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { login_clear } = login_slice.actions;

export const login = createSelector(
  (state: RootState) => state.system_user.login.result,
  (state: RootState) => state.system_user.login.error,
  (state: RootState) => state.system_user.login.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default login_slice.reducer;
