import { combineReducers } from '@reduxjs/toolkit';
import create_stock_transfer_log_list_iterative_reducer from './create_stock_transfer_log_list_iterative';
import get_warehouse_stock_reducer from './get_warehouse_stock';

const stock_transfer_log_reducer = combineReducers({
  create_stock_transfer_log_list_iterative:
    create_stock_transfer_log_list_iterative_reducer,
  get_warehouse_stock: get_warehouse_stock_reducer,
});

export default stock_transfer_log_reducer;
