import React from "react";
import { Route } from "@src/redux";
const WarehouseSummary = React.lazy(
  () => import("@src/views/pages/warehouse_summary")
);
const ProductGST = React.lazy(() => import("@src/views/pages/product_gst"));
const State = React.lazy(() => import("@src/views/pages/state"));
const District = React.lazy(() => import("@src/views/pages/district"));
const OrderCancelled = React.lazy(
  () => import("@src/views/pages/order_cancelled")
);
const Coupon = React.lazy(() => import("@src/views/pages/coupon"));
const AdminLayout = React.lazy(() => import("@src/views/layouts/admin_layout"));
const CustomerLayout = React.lazy(
  () => import("@src/views/layouts/customer_layout")
);
const ProfileLayout = React.lazy(
  () => import("@src/views/layouts/profile_layout")
);
const StockKeepingUnit = React.lazy(
  () => import("@src/views/pages/stock_keeping_unit")
);
const DeliveryDates = React.lazy(
  () => import("@src/views/pages/delivery_dates")
);
const FarmerOrders = React.lazy(() => import("@src/views/pages/farmer_orders"));
const Farmers = React.lazy(() => import("@src/views/pages/farmers"));
const CustomerProfile = React.lazy(
  () => import("@src/views/pages/customer/customer_profile")
);
const CustomerOrders = React.lazy(
  () => import("@src/views/pages/customer/customer_orders")
);
const Wishlist = React.lazy(() => import("@src/views/pages/wishlist"));
const CustomerAddress = React.lazy(
  () => import("@src/views/pages/customer/customer_address")
);
const Home = React.lazy(() => import("@src/views/pages/home"));
const CustomerProducts = React.lazy(
  () => import("@src/views/pages/customer/customer_products")
);
const CustomerOrderReturn = React.lazy(
  () => import("@src/views/pages/customer/customer_order_return")
);
const Checkout = React.lazy(() => import("@src/views/pages/checkout"));
const OrderPlaced = React.lazy(() => import("@src/views/pages/order_placed"));
const FarmerOrderDetails = React.lazy(
  () => import("@src/views/components/farmer_order/farmer_order_details")
);
const FarmerDetails = React.lazy(
  () => import("@src/views/components/farmer/farmer_detail")
);
const StockGrading = React.lazy(() => import("@src/views/pages/stock_grading"));
const CustomerLogin = React.lazy(
  () => import("@src/views/pages/customer/customer_login")
);
const AccessRights = React.lazy(() => import("@src/views/pages/access_rights"));
const CustomerProductDetails = React.lazy(
  () => import("@src/views/pages/customer/customer_product_details")
);
const Cart = React.lazy(() => import("@src/views/pages/cart"));
const OrderList = React.lazy(
  () => import("@src/views/components/customer/orders/order_list")
);
const PersonalInformationForm = React.lazy(
  () => import("@src/views/components/customer/profile/profile")
);
const ManageAddress = React.lazy(
  () => import("@src/views/components/customer/address/manage_address")
);
const Staff = React.lazy(() => import("@src/views/pages/staff"));
const OrderTracking = React.lazy(
  () => import("@src/views/pages/order_tracking")
);
const Vehicle = React.lazy(() => import("@src/views/pages/vehicle"));
const Cluster = React.lazy(() => import("@src/views/pages/cluster"));
const TrackingDetails = React.lazy(
  () => import("@src/views/layouts/admin_layout/tracking/tracking_details")
);

const AdminCustomerOrders = React.lazy(
  () => import("@src/views/components/customer_order/customer_order_list")
);
const CustomerOrderDetail = React.lazy(
  () => import("@src/views/components/customer_order/customer_order_detail")
);
const Zone = React.lazy(() => import("@src/views/pages/zone"));
const ZoneList = React.lazy(() => import("@src/views/pages/zone_list"));
const PackingTeamDetails = React.lazy(
  () => import("@src/views/components/packing_teams/packing_team_details")
);
const ProductCategory = React.lazy(
  () => import("@src/views/pages/product_category")
);
const CustomerList = React.lazy(() => import("@src/views/pages/customer"));
const CustomerProfilePage = React.lazy(
  () => import("@src/views/components/customer_list/customer_profile")
);
const Product = React.lazy(() => import("@src/views/pages/product"));
const ProductVariant = React.lazy(
  () => import("@src/views/pages/product_variant")
);
const PackingSuggestion = React.lazy(
  () => import("@src/views/pages/packing_suggestion")
);
const PackingTeam = React.lazy(() => import("@src/views/pages/packing_team"));
const ProductDetails = React.lazy(
  () => import("@src/views/components/products/product_details")
);

const AdminLogin = React.lazy(() => import("@src/views/pages/admin_login"));
const ProductDemand = React.lazy(
  () => import("@src/views/pages/product_demand")
);
const Warhorse = React.lazy(() => import("@src/views/pages/warhorse"));
const WarehouseDemand = React.lazy(
  () => import("@src/views/pages/warhouse_demand")
);

const ProductList = React.lazy(
  () => import("@src/views/components/customer/product_list/product_list")
);
const SearchResults = React.lazy(
  () => import("@src/views/pages/customer/search_results")
);
const PiscesConversation = React.lazy(
  () => import("@src/views/pages/pieces_conversion")
);
const TransactionHistory = React.lazy(
  () => import("@src/views/pages/transaction_history")
);
const my_routes: Route[] = [
  {
    path: "admin",
    name: "Login",
    authenticate: false,
    component: <AdminLogin />,
  },
  {
    path: "/admin",
    name: "AdminLayout",
    authenticate: false,
    component: <AdminLayout />,
    children: [
      // {
      //   path: "stock-keeping-unit",
      //   name: "StockKeepingUnit",
      //   authenticate: true,
      //   component: <StockKeepingUnit />,
      //   children: [],
      // },
      {
        path: "stock-keeping-unit",
        name: "StockKeepingUnit",
        authenticate: true,
        component: <StockGrading />,
        children: [],
      },
      {
        path: "product-demand",
        name: "ProductDemand",
        authenticate: true,
        component: <ProductDemand />,
        children: [],
      },

      {
        path: "delivery-dates",
        name: "DeliveryDates",
        authenticate: true,
        component: <DeliveryDates />,
        children: [],
      },
      {
        path: "farmer-orders",
        name: "FarmerOrders",
        authenticate: true,
        component: <FarmerOrders />,
        children: [],
      },
      {
        path: "farmer-order/:id",
        name: "FarmerOrders",
        authenticate: true,
        component: <FarmerOrderDetails />,
        children: [],
      },
      {
        path: "farmers",
        name: "Farmers",
        authenticate: true,
        component: <Farmers />,
        children: [],
      },
      {
        path: "farmer/:id",
        name: "Farmer Details",
        authenticate: true,
        component: <FarmerDetails />,
        children: [],
      },
      {
        path: "access-rights",
        name: "Access Rights",
        authenticate: false,
        component: <AccessRights />,
        children: [],
      },
      {
        path: "staff",
        name: "Staff",
        authenticate: false,
        component: <Staff />,
        children: [],
      },
      {
        path: "product-gst",
        name: "Product GST",
        authenticate: false,
        component: <ProductGST />,
        children: [],
      },

      {
        path: "coupon",
        name: "Coupon",
        authenticate: false,
        component: <Coupon />,
        children: [],
      },
      {
        path: "packing-team",
        name: "Packing Team",
        authenticate: false,
        component: <PackingTeam />,
        children: [],
      },
      {
        path: "packing-team/:id?",
        name: "Packing Team",
        authenticate: false,
        component: <PackingTeamDetails />,
        children: [],
      },
      {
        path: "packing-team/add",
        name: "Packing Team",
        authenticate: false,
        component: <PackingTeamDetails />,
        children: [],
      },
      {
        path: "cluster",
        name: "Cluster",
        authenticate: false,
        component: <Cluster />,
        children: [],
      },
      {
        path: "cluster-orders",
        name: "Cluster Orders",
        authenticate: false,
        component: <OrderTracking />,
        children: [],
      },
      {
        path: "cluster-order/:id",
        name: "Cluster Order",
        authenticate: false,
        component: <TrackingDetails />,
      },
      {
        path: "vehicle",
        name: "Vehicle",
        authenticate: false,
        component: <Vehicle />,
      },
      {
        path: "categories",
        name: "Categories",
        authenticate: false,
        component: <ProductCategory />,
      },

      {
        path: "customers",
        name: "Customer",
        authenticate: false,
        component: <CustomerList />,
      },
      {
        path: "customer/:id?",
        name: "Customer Profile",
        authenticate: false,
        component: <CustomerProfilePage />,
      },
      {
        path: "packing-suggestion",
        name: "Packing Suggestion",
        authenticate: false,
        component: <PackingSuggestion />,
      },
      {
        path: "customer-orders",
        name: "Customer Orders",
        authenticate: false,
        component: <AdminCustomerOrders />,
        children: [],
      },
      {
        path: "zone",
        name: "Zones",
        authenticate: false,
        component: <Zone />,
        children: [],
      },
      {
        path: "warehouse-summary/:id?",
        name: "Warehouse Summary",
        authenticate: false,
        component: <WarehouseSummary />,
        children: [],
      },
      {
        path: "zone-group",
        name: "Zone Group",
        authenticate: false,
        component: <ZoneList />,
        children: [],
      },
      {
        path: "product-variant/:id?",
        name: "Product Variant",
        authenticate: false,
        component: <ProductVariant />,
        children: [],
      },
      {
        path: "district/:id?",
        name: "District",
        authenticate: false,
        component: <District />,
        children: [],
      },
      {
        path: "district/:id?",
        name: "District",
        authenticate: false,
        component: <ProductVariant />,
        children: [],
      },
      {
        path: "products",
        name: "Products",
        authenticate: false,
        component: <Product />,
        children: [],
      },
      {
        path: "state",
        name: "State",
        authenticate: false,
        component: <State />,
        children: [],
      },
      {
        path: "product/:id?",
        name: "Products",
        authenticate: false,
        component: <ProductDetails />,
        children: [],
      },
      {
        path: "customer-order/:id",
        name: "Customer Order Details",
        authenticate: false,
        component: <CustomerOrderDetail />,
        children: [],
      },
      {
        path: "warehouse",
        name: "Warehouses",
        authenticate: false,
        component: <Warhorse />,
        children: [],
      },
      {
        path: "warehouse-demand",
        name: "Warehouse Demand",
        authenticate: true,
        component: <WarehouseDemand />,
        children: [],
      },
      {
        path: "pisces-conversion",
        name: "Pieces Conversion",
        authenticate: true,
        component: <PiscesConversation />,
        children: [],
      },
      {
        path: "transaction-history",
        name: "Transaction History",
        authenticate: true,
        component: <TransactionHistory />,
        children: [],
      },
    ],
  },
  {
    path: "/",
    name: "CustomerLayout",
    authenticate: false,
    component: <CustomerLayout />,
    children: [
      {
        path: "profile",
        name: "ProfileLayout",
        authenticate: false,
        component: <ProfileLayout />,
        children: [
          {
            path: "information",
            name: "Profile",
            authenticate: false,
            component: <PersonalInformationForm />,
            children: [],
          },
          {
            path: "orders",
            name: "Order",
            authenticate: false,
            component: <CustomerOrders />,
            children: [],
          },
          {
            path: "wishlist",
            name: "Wishlist",
            authenticate: false,
            component: <Wishlist />,
            children: [],
          },
          {
            path: "address",
            name: "Address",
            authenticate: false,
            component: <ManageAddress />,
            children: [],
          },
        ],
      },
      {
        path: "",
        name: "Home",
        authenticate: false,
        component: <Home />,
        children: [],
      },
      {
        path: "products/:id?",
        name: "Products",
        authenticate: false,
        component: <CustomerProducts />,
        children: [],
      },
      {
        path: "product/:id",
        name: "Product Details",
        authenticate: false,
        component: <CustomerProductDetails />,
      },
      {
        path: "order-return",
        name: "OrderReturn",
        authenticate: false,
        component: <CustomerOrderReturn />,
        children: [],
      },
      {
        path: "checkout",
        name: "Checkout",
        authenticate: false,
        component: <Checkout />,
        children: [],
      },
      {
        path: "order-placed/:order_reference_no/:order_id",
        name: "OrderPlaced",
        authenticate: false,
        component: <OrderPlaced />,
        children: [],
      },

      {
        path: "order-cancelled/:id?",
        name: "OrderCancelled",
        authenticate: false,
        component: <OrderCancelled />,
        children: [],
      },
      {
        path: "customer-address",
        name: "CustomerAddress",
        authenticate: false,
        component: <ManageAddress />,
        children: [],
      },
      {
        path: "checkout",
        name: "CheckOut",
        authenticate: false,
        component: <Checkout />,
        children: [],
      },
      {
        path: "cart",
        name: "Cart",
        authenticate: false,
        component: <Cart />,
      },
      {
        path: "login",
        name: "Login",
        authenticate: false,
        component: <CustomerLogin />,
        children: [],
      },
      {
        path: "order-list",
        name: "OrderList",
        authenticate: false,
        component: <OrderList />,
      },
      {
        path: "search-results/:value?",
        name: "SearchResults",
        authenticate: false,
        component: <SearchResults />,
      },
      {
        path: "order-refund",
        name: "OrderReturn",
        authenticate: false,
        component: <CustomerOrderReturn />,
      },
    ],
  },
];

export default my_routes;
