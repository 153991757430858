import { combineReducers } from '@reduxjs/toolkit';
import create_product_variant_reducer from './create_product_variant';
import delete_product_variant_reducer from './delete_product_variant';
import get_product_variant_reducer from './get_product_variant';
import get_product_variant_list_reducer from './get_product_variant_list';
import update_product_variant_reducer from './update_product_variant';

const product_variant_reducer = combineReducers({
  create_product_variant: create_product_variant_reducer,
  delete_product_variant: delete_product_variant_reducer,
  get_product_variant: get_product_variant_reducer,
  get_product_variant_list: get_product_variant_list_reducer,
  update_product_variant: update_product_variant_reducer,
});

export default product_variant_reducer;
