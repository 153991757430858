import { combineReducers } from '@reduxjs/toolkit';
import create_feedback_reducer from './create_feedback';
import delete_feedback_reducer from './delete_feedback';
import get_feedback_list_reducer from './get_feedback_list';
import update_feedback_reducer from './update_feedback';

const feedback_reducer = combineReducers({
  create_feedback: create_feedback_reducer,
  delete_feedback: delete_feedback_reducer,
  get_feedback_list: get_feedback_list_reducer,
  update_feedback: update_feedback_reducer,
});

export default feedback_reducer;
