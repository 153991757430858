// Logo and Profile Images
export const LOGO_WIDTH_TITLE = require("@src/assets/icons/logo.png");
export const VERTACE_LOGO = require("@src/assets/images/vertace_logo.png");
export const PROFILE = require("@src/assets/images/profile_img.jpg");
export const SIDEBAR_IMAGE = require("@src/assets/images/sidebar_image.png");
export const NO_PRODUCT = require("@src/assets/icons/no_product.gif");
export const LOGO = require("@src/assets/icons/My harvest logo.png");
export const PENDING = require("@src/assets/icons/pending.png");

// Menu Icons
export const FARMER_ORDER_ICON = require("@src/assets/icons/farmer_order.png");
export const FARMER_ORDER_ACTIVE_ICON = require("@src/assets/icons/farmer_order_active.png");
export const PRODUCT_DEMAND_ACTIVE_ICON = require("@src/assets/icons/product_demand_active.png");
export const PRODUCT_DEMAND = require("@src/assets/icons/product_demand.png");

// Product Images
export const Beetroot = require("@src/assets/images/products/Beetroot.png");
export const Fruits = require("@src/assets/images/products/Fruits.png");
export const Ginger = require("@src/assets/images/products/Ginger.png");
export const Turnip = require("@src/assets/images/products/Turnip.png");
export const apple = require("@src/assets/images/products/apple.png");
export const banana = require("@src/assets/images/products/banana.png");
export const broad_beans = require("@src/assets/images/products/broad_beans.png");
export const broccoli = require("@src/assets/images/products/broccoli.png");
export const carrot = require("@src/assets/images/products/carrot.png");
export const chow_chow = require("@src/assets/images/products/chow_chow.png");
export const green_papaya = require("@src/assets/images/products/green_papaya.png");
export const snake_beans = require("@src/assets/images/products/snake_beans.png");
export const spring_onion = require("@src/assets/images/products/spring_onion.png");
export const veg = require("@src/assets/images/products/veg.png");
export const onion = require("@src/assets/images/products/onion.png");
export const garlic = require("@src/assets/images/products/garlic.png");
export const watermelon = require("@src/assets/images/products/watermelon.png");

// Product Icons
export const pomegranate_icon = require("@src/assets/images/products/pomegranate.jpg");
export const apple_icon = require("@src/assets/images/products/red apple_icon.jpg");
export const orange_icon = require("@src/assets/images/products/orange-icon.jpg");
export const cauliflower_icon = require("@src/assets/images/products/cauliflower-icon.jpg");
export const mango_icon = require("@src/assets/images/products/mango_icon.jpg");
export const watermelon_icon = require("@src/assets/images/products/watermelon2.jpg");

// Order Status ImagesP
export const order_placed = require("@src/assets/images/order_placed.png");
export const order_canceled = require("@src/assets/images/order_cancelled.png");
export const instagram = require("@src/assets/images/instagram.png");

export const facebook = require("@src/assets/images/facebook.png");

export const whats_app = require("@src/assets/images/whats_app.png");
export const share_icon = require("@src/assets/images/share.png");


// Customer Home Page Images
export const customer_home_header_img = require("@src/assets/images/customer_home/home_slider_img.png");
export const customer_home_farms_img = require("@src/assets/images/customer_home/my_harvest_forms_img.png");
export const shopping_logo_home = require("@src/assets/images/customer_home/shopping_logo.png");
export const pay_home_logo = require("@src/assets/images/customer_home/pay_logo.png");
export const location_home_logo = require("@src/assets/images/customer_home/location_logo.png");
export const plantation_home_logo = require("@src/assets/images/customer_home/plant_logo.png");
export const show_now_img1 = require("@src/assets/images/customer_home/fresh_option_img1.png");
export const show_now_img2 = require("@src/assets/images/customer_home/fresh_option_img2.png");
export const show_now_img3 = require("@src/assets/images/customer_home/fresh_option_img3.png");
export const why_myharvest_img = require("@src/assets/images/customer_home/why_myharvest_img.png");
export const all_product_img = require("@src/assets/images/all_product.png");

// Footer Images
export const footer_background_image = require("@src/assets/images/image.png");

// Social Media Icons
export const WHATSAPP = require("@src/assets/icons/what's_app_logo.png");
export const FACEBOOK = require("@src/assets/icons/facebook.png");
export const INSTAGRAM = require("@src/assets/icons/instagram.png");
export const YOUTUBE = require("@src/assets/icons/youtube.png");

// Login Background
export const CUSTOMER_LOGIN_BACKGROUND = require("@src/assets/images/customer_login_background.jpg");

//Cart Images
export const empty_cart_img = require("../assets/images/empty_cart_img.png");

//Wishlist Images
export const empty_wishlist_img = require("../assets/images/empty_wishlist_img.png");

//Order Images
export const empty_order_img = require("../assets/images/empty_orders_img.png");

//Address Images
export const empty_address_img = require("../assets/images/empty_address_img.png");

// cluster
export const TruckImage = require("../assets/images/isometric-truck-icon.png");
export const OrderBoxImage = require("../assets/images/logistics.png");
