import { combineReducers } from '@reduxjs/toolkit';
import create_customer_address_reducer from './create_customer_address';
import delete_customer_address_reducer from './delete_customer_address';
import get_customer_address_reducer from './get_customer_address';
import get_customer_address_list_reducer from './get_customer_address_list';
import update_customer_address_reducer from './update_customer_address';

const customer_address_reducer = combineReducers({
  create_customer_address: create_customer_address_reducer,
  delete_customer_address: delete_customer_address_reducer,
  get_customer_address: get_customer_address_reducer,
  get_customer_address_list: get_customer_address_list_reducer,
  update_customer_address: update_customer_address_reducer,
});

export default customer_address_reducer;
