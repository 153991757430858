import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  query get_customer_address($filter: customer_address_filter_input) {
    get_customer_address(filter: $filter) {
      id
      customer_id
      is_default
      zone_id
      district_id
      state_id
      floor
      house_no
      address_line_1
      address_line_2
      landmark
      pincode
      building_type
      customer {
        mobile
        name
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.CustomerAddressState = {
  result: null,
  error: null,
  loading: false,
};

export const get_customer_address_request = createAsyncThunk<
  TYPES.CustomerAddress,
  { filter?: TYPES.CustomerAddressFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'customer_address/get_customer_address',
  async (
    params: { filter?: TYPES.CustomerAddressFilterInput },
    { extra: client },
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_customer_address;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const get_customer_address_slice = createSlice({
  name: 'get_customer_address',
  initialState: initial_state,
  reducers: {
    get_customer_address_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(get_customer_address_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_customer_address_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_customer_address_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { get_customer_address_clear } =
  get_customer_address_slice.actions;

export const get_customer_address = createSelector(
  (state: RootState) => state.customer_address.get_customer_address.result,
  (state: RootState) => state.customer_address.get_customer_address.error,
  (state: RootState) => state.customer_address.get_customer_address.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default get_customer_address_slice.reducer;
