import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  query get_customer_order($filter: customer_order_filter_input) {
    get_customer_order(filter: $filter) {
      id
      order_reference
      customer_id
      status
      return_status
      return_image_list
      return_remarks
      actual_total_cost
      final_total_cost
      delivery_remarks
      payment_link
      total_items
      total_weight
      invoice_url
      customer {
        gender
        email
        mobile
        name
        id
      }
      customer_address {
        zone {
          name
          pincode
        }
        district {
          name
        }
        state {
          name
        }
        building_type
        pincode
        landmark
        address_line_2
        address_line_1
        house_no
        floor
        mobile
        name
      }
      delivery_date {
        date
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.CustomerOrderState = {
  result: null,
  error: null,
  loading: false,
};

export const get_customer_order_request = createAsyncThunk<
  TYPES.CustomerOrder,
  { filter?: TYPES.CustomerOrderFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'customer_order/get_customer_order',
  async (
    params: { filter?: TYPES.CustomerOrderFilterInput },
    { extra: client },
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_customer_order;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const get_customer_order_slice = createSlice({
  name: 'get_customer_order',
  initialState: initial_state,
  reducers: {
    get_customer_order_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(get_customer_order_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_customer_order_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_customer_order_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { get_customer_order_clear } = get_customer_order_slice.actions;

export const get_customer_order = createSelector(
  (state: RootState) => state.customer_order.get_customer_order.result,
  (state: RootState) => state.customer_order.get_customer_order.error,
  (state: RootState) => state.customer_order.get_customer_order.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default get_customer_order_slice.reducer;
