import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  query get_customer_cart_line_item_list(
    $filter: customer_cart_line_item_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_customer_cart_line_item_list(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      items {
        product {
          id
          name
          description
          image_list
          category_id
          category {
            id
            name
            type
          }
          gst {
            percentage
          }
        }
        product_variant {
          id
          name
          description
          quantity
          price
          max_price
          min_price
          weight
          common_factor
          measurement_type
        }
        quantity
        product_variant_id
        product_id
        customer_cart_id
        id
      }
      pagination {
        total_count
        page_limit
        page_number
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.CustomerCartLineItemListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_customer_cart_line_item_list_request = createAsyncThunk<
  TYPES.CustomerCartLineItemList,
  {
    filter?: TYPES.CustomerCartLineItemFilterInput;
    pagination?: TYPES.PaginationInput;
    sort?: TYPES.SortInput[];
  },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'customer_cart_line_item/get_customer_cart_line_item_list',
  async (
    params: {
      filter?: TYPES.CustomerCartLineItemFilterInput;
      pagination?: TYPES.PaginationInput;
      sort?: TYPES.SortInput[];
    },
    { extra: client },
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_customer_cart_line_item_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const get_customer_cart_line_item_list_slice = createSlice({
  name: 'get_customer_cart_line_item_list',
  initialState: initial_state,
  reducers: {
    get_customer_cart_line_item_list_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(get_customer_cart_line_item_list_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(
        get_customer_cart_line_item_list_request.fulfilled,
        (state, action) => {
          state.result = action.payload;
          state.loading = false;
        },
      )
      .addCase(
        get_customer_cart_line_item_list_request.rejected,
        (state, action) => {
          state.error = (action.payload as Error) || {
            message: 'Failed to fetch',
            status_code: '500',
          };
          state.loading = false;
        },
      );
  },
});

export const { get_customer_cart_line_item_list_clear } =
  get_customer_cart_line_item_list_slice.actions;

export const get_customer_cart_line_item_list = createSelector(
  (state: RootState) =>
    state.customer_cart_line_item.get_customer_cart_line_item_list.result,
  (state: RootState) =>
    state.customer_cart_line_item.get_customer_cart_line_item_list.error,
  (state: RootState) =>
    state.customer_cart_line_item.get_customer_cart_line_item_list.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default get_customer_cart_line_item_list_slice.reducer;
