import { combineReducers } from '@reduxjs/toolkit';
import cancel_customer_order_reducer from './cancel_customer_order';
import get_customer_order_reducer from './get_customer_order';
import get_customer_order_list_reducer from './get_customer_order_list';
import get_customer_order_list_without_pagination_reducer from './get_customer_order_list_without_pagination';
import get_zone_wise_customer_order_reducer from './get_zone_wise_customer_order';

const customer_order_reducer = combineReducers({
  cancel_customer_order: cancel_customer_order_reducer,
  get_customer_order: get_customer_order_reducer,
  get_customer_order_list: get_customer_order_list_reducer,
  get_customer_order_list_without_pagination:
    get_customer_order_list_without_pagination_reducer,
  get_zone_wise_customer_order: get_zone_wise_customer_order_reducer,
});

export default customer_order_reducer;
