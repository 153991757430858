import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  query get_cluster_order($filter: cluster_order_filter_input) {
    get_cluster_order(filter: $filter) {
      id
      cluster_id
      customer_order_id
      delivery_order_number
      delivery_status
      remarks
      cluster {
        id
        delivery_date_id
        vehicle_id
        driver_id
        status
        vehicle {
          id
          driver_id
          name
          vehicle_number
        }
        driver {
          id
          name
          mobile
        }
        warehouse {
          id
          name
          address
          created_datetime
        }
      }
      customer_order {
        id
        order_reference
        customer_id
        status
        customer {
          id
          name
          mobile
          email
        }
        customer_address {
          id
          name
          mobile
          floor
          house_no
          address_line_1
          landmark
          pincode
          zone {
            id
            name
          }
          district {
            id
            name
          }
        }
      }
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.ClusterOrderState = {
  result: null,
  error: null,
  loading: false,
};

export const get_cluster_order_request = createAsyncThunk<
  TYPES.ClusterOrder,
  { filter?: TYPES.ClusterOrderFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'cluster_order/get_cluster_order',
  async (
    params: { filter?: TYPES.ClusterOrderFilterInput },
    { extra: client },
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_cluster_order;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const get_cluster_order_slice = createSlice({
  name: 'get_cluster_order',
  initialState: initial_state,
  reducers: {
    get_cluster_order_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(get_cluster_order_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_cluster_order_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_cluster_order_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { get_cluster_order_clear } = get_cluster_order_slice.actions;

export const get_cluster_order = createSelector(
  (state: RootState) => state.cluster_order.get_cluster_order.result,
  (state: RootState) => state.cluster_order.get_cluster_order.error,
  (state: RootState) => state.cluster_order.get_cluster_order.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default get_cluster_order_slice.reducer;
