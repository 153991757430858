import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  query get_cluster($filter: cluster_filter_input) {
    get_cluster(filter: $filter) {
      id
      delivery_date_id
      vehicle_id
      driver_id
      warehouse_id
      status
      created_datetime
      vehicle {
        vehicle_type
        vehicle_number
        capacity
        name
        driver_id
        id
      }
      driver {
        email
        mobile
        name
        id
      }
      warehouse {
        mobile
        address
        name
        id
      }
      cluster_order_list {
        customer_order {
          id
          order_reference
          customer_id
          customer_address_id
          ordered_datetime
          delivery_date_id
          status
        }
        remarks
        delivery_status
        delivery_order_number
        customer_order_id
        cluster_id
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.ClusterState = {
  result: null,
  error: null,
  loading: false,
};

export const get_cluster_request = createAsyncThunk<
  TYPES.Cluster,
  { filter?: TYPES.ClusterFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'cluster/get_cluster',
  async (params: { filter?: TYPES.ClusterFilterInput }, { extra: client }) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_cluster;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const get_cluster_slice = createSlice({
  name: 'get_cluster',
  initialState: initial_state,
  reducers: {
    get_cluster_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(get_cluster_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_cluster_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_cluster_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { get_cluster_clear } = get_cluster_slice.actions;

export const get_cluster = createSelector(
  (state: RootState) => state.cluster.get_cluster.result,
  (state: RootState) => state.cluster.get_cluster.error,
  (state: RootState) => state.cluster.get_cluster.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default get_cluster_slice.reducer;
