import { combineReducers } from '@reduxjs/toolkit';
import create_zone_group_reducer from './create_zone_group';
import delete_zone_group_reducer from './delete_zone_group';
import get_zone_group_reducer from './get_zone_group';
import get_zone_group_list_reducer from './get_zone_group_list';
import update_zone_group_reducer from './update_zone_group';

const zone_group_reducer = combineReducers({
  create_zone_group: create_zone_group_reducer,
  delete_zone_group: delete_zone_group_reducer,
  get_zone_group: get_zone_group_reducer,
  get_zone_group_list: get_zone_group_list_reducer,
  update_zone_group: update_zone_group_reducer,
});

export default zone_group_reducer;
