import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  mutation create_category($data: create_category_input!) {
    create_category(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result: null,
  error: null,
  loading: false,
};

export const create_category_request = createAsyncThunk<
  TYPES.MutationResponse,
  { data: TYPES.CreateCategoryInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'category/create_category',
  async (params: { data: TYPES.CreateCategoryInput }, { extra: client }) => {
    try {
      const response = await client.mutate({
        mutation: GRAPHQL,
        variables: params,
      });
      return response.data.create_category;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const create_category_slice = createSlice({
  name: 'create_category',
  initialState: initial_state,
  reducers: {
    create_category_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(create_category_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(create_category_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(create_category_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { create_category_clear } = create_category_slice.actions;

export const create_category = createSelector(
  (state: RootState) => state.category.create_category.result,
  (state: RootState) => state.category.create_category.error,
  (state: RootState) => state.category.create_category.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default create_category_slice.reducer;
