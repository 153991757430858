import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  query get_product_list(
    $filter: product_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_product_list(filter: $filter, pagination: $pagination, sort: $sort) {
      items {
        category {
          id
          name
          type
        }
        variant_list {
          id
          name
          quantity
          price
          measurement_type
        }
        total_stock_quantity
        measurement_type
        image_list
        ta_name
        name
        id
      }
      pagination {
        total_count
        page_limit
        page_number
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.ProductListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_product_list_request = createAsyncThunk<
  TYPES.ProductList,
  {
    filter?: TYPES.ProductFilterInput;
    pagination?: TYPES.PaginationInput;
    sort?: TYPES.SortInput[];
  },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  'product/get_product_list',
  async (
    params: {
      filter?: TYPES.ProductFilterInput;
      pagination?: TYPES.PaginationInput;
      sort?: TYPES.SortInput[];
    },
    { extra: client },
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_product_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || 'An error occurred',
        status_code: err.status_code || '500',
      });
    }
  },
);

const get_product_list_slice = createSlice({
  name: 'get_product_list',
  initialState: initial_state,
  reducers: {
    get_product_list_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(get_product_list_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_product_list_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_product_list_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { get_product_list_clear } = get_product_list_slice.actions;

export const get_product_list = createSelector(
  (state: RootState) => state.product.get_product_list.result,
  (state: RootState) => state.product.get_product_list.error,
  (state: RootState) => state.product.get_product_list.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default get_product_list_slice.reducer;
