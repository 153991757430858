import React, { Suspense } from "react";
import lodash from "lodash";

import { useCheckLogin, useCheckPageAccess } from "@src/hooks";
import { PROJECT_NAME } from "@src/helpers/constants";
import { Navigate } from "react-router-dom";

export default function MyRoute({
  name,
  authenticate,
  children,
}: {
  name: string;
  authenticate: boolean;
  children: React.ReactNode;
}) {
  if (name) {
    let page_name = lodash.startCase(lodash.camelCase(name));
    document.title = page_name + " | " + PROJECT_NAME;
  } else document.title = PROJECT_NAME;

  const isAuthenticated = useCheckLogin();
  return isAuthenticated || !authenticate ? (
    children
  ) : (
    <Navigate to="/not-allowed" />
  );
}
