import { combineReducers } from '@reduxjs/toolkit';
import create_piece_conversion_reducer from './create_piece_conversion';
import delete_piece_conversion_reducer from './delete_piece_conversion';
import get_piece_conversion_reducer from './get_piece_conversion';
import get_piece_conversion_list_reducer from './get_piece_conversion_list';
import update_piece_conversion_reducer from './update_piece_conversion';

const piece_conversion_reducer = combineReducers({
  create_piece_conversion: create_piece_conversion_reducer,
  delete_piece_conversion: delete_piece_conversion_reducer,
  get_piece_conversion: get_piece_conversion_reducer,
  get_piece_conversion_list: get_piece_conversion_list_reducer,
  update_piece_conversion: update_piece_conversion_reducer,
});

export default piece_conversion_reducer;
