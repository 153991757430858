import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  mutation delete_staff($id: String!) {
    delete_staff(id: $id) {
      id
      status
      error {
        message
        status_code
      }
    }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result: null,
  error: null,
  loading: false,
};

export const delete_staff_request = createAsyncThunk<
  TYPES.MutationResponse,
  { id: String },
  { extra: ApolloClient<NormalizedCacheObject> }
>('staff/delete_staff', async (params: { id: String }, { extra: client }) => {
  try {
    const response = await client.mutate({
      mutation: GRAPHQL,
      variables: params,
    });
    return response.data.delete_staff;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || 'An error occurred',
      status_code: err.status_code || '500',
    });
  }
});

const delete_staff_slice = createSlice({
  name: 'delete_staff',
  initialState: initial_state,
  reducers: {
    delete_staff_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(delete_staff_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(delete_staff_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(delete_staff_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { delete_staff_clear } = delete_staff_slice.actions;

export const delete_staff = createSelector(
  (state: RootState) => state.staff.delete_staff.result,
  (state: RootState) => state.staff.delete_staff.error,
  (state: RootState) => state.staff.delete_staff.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default delete_staff_slice.reducer;
