import { configureStore } from "@reduxjs/toolkit";
import {
  ApolloClient,
  ApolloLink,
  concat,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { retrieve_item } from "@src/helpers/functions";
import { BASE_URL } from "@src/helpers/constants";
import reducer from "./reducer";

const httpLink = new HttpLink({
  uri: BASE_URL,
});

const authLink = new ApolloLink((operation, forward) => {
  const session_id = retrieve_item("session_id");
  const delivery_address_id = retrieve_item("delivery_address_id");

  operation.setContext({
    headers: {
      session_id: session_id ?? "",
      // customer_address_id: delivery_address_id ?? "",
    },
  });

  return forward(operation);
});
const defaultOptions: any = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
export const client = new ApolloClient({
  link: concat(authLink, httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: defaultOptions,
});

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: client,
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
