import { combineReducers } from '@reduxjs/toolkit';
import create_system_role_reducer from './create_system_role';
import delete_system_role_reducer from './delete_system_role';
import get_system_role_list_reducer from './get_system_role_list';
import update_system_role_reducer from './update_system_role';

const system_role_reducer = combineReducers({
  create_system_role: create_system_role_reducer,
  delete_system_role: delete_system_role_reducer,
  get_system_role_list: get_system_role_list_reducer,
  update_system_role: update_system_role_reducer,
});

export default system_role_reducer;
