import {
  client,
  CustomerAddress,
  CustomerCartLineItem,
  CustomerOrder,
} from "@src/redux";
import moment, { Moment } from "moment";
import * as XLSX from "xlsx";
import { LOGO_WIDTH_TITLE } from "./images_constants";
import { mukta_bold, mukta_normal } from "./font_constants";
import { ToWords } from "to-words";
import jsPDF from "jspdf";
import "jspdf-autotable";

export const PROJECT_NAME = "myHarvest";
// export const BASE_URL = "https://myharvest-sit.vertace.org/api/graphql";
export const BASE_URL = "https://myharvest.vertace.org/api/graphql";
// export const BASE_URL = "http://192.168.1.35:6226/graphql";

export const disable_date = (current: Moment | null): boolean => {
  return !!(current && current < moment().startOf("day"));
};

export const measurement_type: Record<string, string> = {
  piece: "pcs",
  bunch: "bch",
  gram: "g",
  kilogram: "kg",
  litre: "l",
  millilitre: "ml",
};

export const delivery_date_format = (
  date: string | Date | undefined
): string => {
  if (!date) return "";
  return moment(new Date(date)).format("DD MMM, dddd");
};

export const format_date = (date: string | Date | undefined): string => {
  if (!date) return "";
  return moment(new Date(date)).format("DD MMM, YYYY");
};

export const delivery_date_disable_condition = (
  current: any,
  validDates: String[] | undefined
): boolean => {
  const today = moment().startOf("day");
  const formatted_date = moment(new Date(current)).format("YYYY-MM-DD");
  return (
    moment(new Date(current)).isBefore(today) ||
    !validDates?.includes(formatted_date)
  );
};

export function indian_currency_format(
  amount: number = 0,
  show_zero: boolean = true
): string {
  let amountStr = amount.toFixed(2);
  let [integerPart, fractionalPart] = amountStr.split(".");

  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);
  if (otherDigits) {
    lastThreeDigits = "," + lastThreeDigits;
  }
  otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  let formattedInt = otherDigits + lastThreeDigits;

  if (show_zero) {
    return (
      "₹" +
      formattedInt +
      (fractionalPart ? "." + fractionalPart : show_zero ? ".00" : "")
    );
  } else {
    return "₹" + formattedInt;
  }
}

export function indian_currency_format_pdf(
  amount: number = 0,
  show_zero: boolean = true
): string {
  if (!show_zero && amount === 0) return "";

  // Convert the amount to a string with two decimal places
  let amountStr = amount.toFixed(2);
  let [integerPart, fractionalPart] = amountStr.split(".");

  // Manually format using Indian numbering system
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);
  if (otherDigits) {
    lastThreeDigits = "," + lastThreeDigits;
  }
  otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");

  const formattedAmount = otherDigits + lastThreeDigits + "." + fractionalPart;

  // Manually add the currency symbol to avoid issues with React-PDF
  return "Rs. " + formattedAmount;
}


export function count_format(amount: number = 0): string {
  let amountStr = amount.toFixed(2);
  let [integerPart, fractionalPart] = amountStr.split(".");

  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);
  if (otherDigits) {
    lastThreeDigits = "," + lastThreeDigits;
  }
  otherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  let formattedInt = otherDigits + lastThreeDigits;

  return fractionalPart ? formattedInt + "." + fractionalPart : formattedInt;
}

export const product_variant_unit: Record<string, string> = {
  piece: "pcs",
  bunch: "bch",
  gram: "g",
  kilogram: "kg",
  litre: "l",
  millilitre: "ml",
};

export const pickup_building_address: Record<string, string> = {
  Independent: "independent",
  Apartment: "apartment",
};

export const logistics_staff_role: Record<string, string> = {
  PackingTeam: "packing team",
  Driver: "driver",
};
export var pluralize = require("pluralize");

export const farmer_order_line_item_status = {
  warehouse_update: [
    {
      label: "Received",
      value: "received",
    },
    {
      label: "Partially Received",
      value: "partially_received",
    },
    {
      label: "Not Received",
      value: "not_received",
    },
    {
      label: "Extra Received",
      value: "extra_received",
    },
  ],
  procurement_update: [
    {
      label: "Paid",
      value: "paid",
    },
    {
      label: "Unpaid",
      value: "unpaid",
    },
    {
      label: "Cancelled by Firm",
      value: "cancelled_by_firm",
    },
    {
      label: "Cancelled by Farmer",
      value: "cancelled_by_farmer",
    },
  ],
  warehouse_approve: [
    {
      label: "Received",
      value: "received",
    },
    {
      label: "Partially Received",
      value: "partially_received",
    },
    {
      label: "Extra Received",
      value: "extra_received",
    },
  ],

  warehouse_reject: [
    {
      label: "Not Received",
      value: "not_received",
    },
  ],
  procurement_reject: [
    {
      label: "Cancelled by Firm",
      value: "cancelled_by_firm",
    },
    {
      label: "Cancelled by Farmer",
      value: "cancelled_by_farmer",
    },
  ],
  location_list: [
    { id: "chennai", name: "Chennai" },
    { id: "outside_chennai", name: "Outside Chennai" },
  ],
  procurement_approve: [
    {
      label: "Paid",
      value: "paid",
    },
    {
      label: "Unpaid",
      value: "unpaid",
    },
  ],
};

export const calculate_status = (
  wastage_quantity: number,
  received_quantity: number,
  ordered_quantity: number
) => {
  console.log(
    "calculate_status",
    wastage_quantity,
    received_quantity,
    ordered_quantity
  );

  if (received_quantity < ordered_quantity) {
    if (received_quantity - wastage_quantity > 0) {
      return "partially_received"; // Received but less than ordered
    } else {
      return "not_received"; // No goods received or only wastage
    }
  } else if (
    (received_quantity === ordered_quantity && wastage_quantity === 0) ||
    received_quantity === ordered_quantity
  ) {
    return "received"; // no wastage
  } else if (received_quantity > ordered_quantity) {
    return "extra_received"; // More received than ordered
  }
};

export const customer_orders_export = (orders: CustomerOrder[]) => {
  // Prepare the data for the excel sheet
  const data = orders
    .map((order, order_index) => {
      const {
        customer,
        order_line_item_list,
        ordered_datetime,
        id,
        customer_address,
      } = order;

      // Assuming customer name is "First Last" format
      const name = customer?.name;

      return order_line_item_list?.map((item, index: number) => ({
        S_NO: order_index + index,
        "Order ID": order.order_reference,
        Date: moment(ordered_datetime).format("DD-MMM-YYYY"),
        Name: name,
        "Phone Number": customer?.mobile,
        "Email ID": customer?.email, // Assuming no email in the data
        District: customer_address?.district?.name, // Assuming district is not provided
        State: customer_address?.state?.name, // Assuming state is not provided
        Pincode: customer_address?.zone?.name || customer_address?.pincode, // Assuming pincode is not provided
        Address: customer_address?.address_line_1
          ? customer_address?.address_line_1
          : "" + "," + customer_address?.address_line_2
          ? customer_address?.address_line_2
          : "", // Assuming address is not provided
        Product: item?.product?.name,
        Variant: item?.product_variant?.name,
        SKU: item.product?.sku_code, // Assuming SKU can be represented by product id
        Quantity: item.quantity,
        Rate: item.actual_cost,
        "Product Total": Number(item?.quantity) * Number(item?.actual_cost),
        "Payment Status": "Placed", // Assuming payment status is static for this example
        Remarks: order?.customer_remarks, // Assuming no remarks are provided
      }));
    })
    .flat();

  // Create a new workbook and a new worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

  XLSX.writeFile(
    workbook,
    `CustomerOrders-${moment().format("DD-MMM-YYYY")}.xlsx`
  );
};

export const payment_status = [
  { value: "initiated", label: "Initiated" },
  { value: "cancelled", label: "Cancelled" },
  { value: "success", label: "Success" },
  { value: "failed", label: "Failed" },
  { value: "already_paid", label: "Already Paid" },
];

export const generatePDF = (
  cart?: CustomerCartLineItem[],
  address?: CustomerAddress,
  invoice_number?: string
) => {
  const toWords = new ToWords();
  const total_amount = cart?.reduce(
    (acc, item) => {
      const quantity = Number(item?.quantity) || 0;
      const price = Number(item?.product_variant?.price) || 0;
      const itemTotal = quantity * price;

      // Calculate GST if there's a percentage defined
      const gstPercentage = Number(item?.product?.gst?.percentage) || 0;
      const gstAmount = itemTotal * (gstPercentage / 100);

      return {
        subtotal: acc.subtotal + itemTotal,
        gst: acc.gst + gstAmount,
      };
    },
    { subtotal: 0, gst: 0 }
  );
  const grandTotal =
    Math.round(
      (Number(total_amount?.subtotal || 0) + Number(total_amount?.gst || 0)) *
        100
    ) / 100;

  const doc = new jsPDF("p", "mm", "a4");

  // Add header image (Logo) - adjust width and height as needed
  const imgWidth = 60; // Adjust the width of the logo
  const imgHeight = 20; // Adjust the height of the logo
  doc.addImage(LOGO_WIDTH_TITLE, "PNG", 14, 10, imgWidth, imgHeight); // Add logo at position (x, y) with size (width, height)
  doc.addFileToVFS("Mukta-Bold.ttf", mukta_bold);
  doc.addFont("Mukta-Bold.ttf", "Mukta", "bold");
  doc.addFileToVFS("Mukta-normal.ttf", mukta_normal);
  doc.addFont("Mukta-normal.ttf", "Mukta", "normal");

  // Add title and other header details
  doc.setFont("Mukta", "bold");
  doc.setFontSize(15);
  doc.text("Invoice # " + invoice_number, 125, 20, { align: "left" }); // Title below the logo
  doc.setFontSize(10);
  doc.text("Invoice Amount", 160, 30, { align: "left" }); // Title below the logo
  doc.setFontSize(13);
  doc.text(
    indian_currency_format(
      Number(total_amount?.subtotal || 0) + Number(total_amount?.gst || 0),
      false
    ),
    170,
    35,
    {
      align: "left",
    }
  ); // Title below the logo

  // Add invoice details
  doc.setFontSize(12);

  doc.text("Bhoominalam myHarvest Farms Private Limited", 14, 50);
  doc.setFont("Mukta", "normal");
  const company_address = doc.splitTextToSize(
    "22A, Subashree Nagar Extension 3, Mugalivakkam, Chennai, Tamil Nadu 600116",
    80
  );
  doc.text(company_address, 14, 57);
  doc.text("GSTIN 33AAHCB8618F1ZR", 14, 68);

  doc.text("Bill To", 14, 80);
  doc.setFont("Mukta", "bold");
  doc.text(`${invoice_number}`, 14, 90);
  doc.setFont("Mukta", "normal");
  doc.text(address?.customer?.name || "", 14, 95);
  const addressText =
    `${address?.address_line_1 ? address?.address_line_1 + "," : ""} ${
      address?.address_line_2 ? address?.address_line_2 + "," : ""
    } ${address?.zone?.name ? address?.zone?.name + "," : ""} ${
      address?.district?.name ? address?.district?.name + "," : ""
    } ${address?.state?.name ? address?.state?.name + "," : ""}- ${
      address?.zone?.pincode ? address?.zone?.pincode + "," : ""
    }` || "-";

  const maxWidth = 80; // Set the width you want for the text wrapping
  const wrappedText = doc.splitTextToSize(addressText, maxWidth);
  doc.text(wrappedText, 14, 100);
  doc.setFontSize(10);
  doc.text("Invoice Date :", 150, 100);
  doc.text("  " + moment().format("DD/MM/YYYY"), 170, 100);
  doc.text("Due Date      :", 150, 106);
  doc.text("  " + moment().format("DD/MM/YYYY"), 170, 106);
  // Add table for items
  (doc as any).autoTable({
    startY: 115,
    head: [["#", "Item", "Packed Qty", "Unit Rate", "GST", "Billed Amount"]],
    body: cart?.map((line_item, index) => {
      const gst_amount = line_item?.product?.gst?.percentage
        ? Number(
            Number(line_item?.quantity) *
              Number(line_item?.product_variant?.price)
          ) *
          (Number(line_item?.product?.gst?.percentage) / 100)
        : 0;
      return [
        index + 1,
        line_item?.product?.name,
        count_format(line_item?.quantity),
        count_format(line_item?.product_variant?.price),
        `${line_item?.product?.gst?.percentage} %`,
        count_format(
          Number(line_item?.quantity) *
            Number(line_item?.product_variant?.price) +
            gst_amount
        ),
      ];
    }),
    theme: "grid",
    styles: {
      fontSize: 10,
      textColor: "black", // Set body text color to black
      lineWidth: 0.5, // Set the line width for borders
      lineColor: "black", // Set the border color to black
      cellPadding: 3.5, // Set cell padding (roughly 10px in mm)
    },
    headStyles: {
      fillColor: "#30ad63", // Header background color
      textColor: "white", // Header text color (optional)
      lineWidth: 0.5, // Header border width
      lineColor: "white", // Header border color
      cellPadding: 3.5, // Set cell padding (roughly 10px in mm)
    },
    columnStyles: {
      0: { cellWidth: 10 }, // Item #
      1: { cellWidth: 70 }, // Item Name
      2: { cellWidth: 20 }, // Quantity
      3: { cellWidth: 30 }, // Unit Price
      4: { cellWidth: 30 }, // Unit Price
      5: { cellWidth: 30 }, // Unit Price
      6: { cellWidth: 35, halign: "right" }, // Right-align Billed Amount column
    },
    didParseCell: function (data) {
      // Apply bottom border to all cells
      if (data.row.section === "body" || data.row.section === "head") {
        data.cell.styles.lineWidth = {
          top: 0,
          right: 0,
          bottom: 0.5,
          left: 0,
        };
        data.cell.styles.lineColor = "black"; // Bottom border color
      }
    },
    didDrawPage: (data: any) => {
      // Add the footer on each page
      let pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      let footerY = pageHeight - 10;
      doc.setFont("Mukta", "normal");
      doc.text(
        "Kindly pay the bills within 2 days maximum to help us pay to our farmers without delay.",
        14,
        footerY - 35
      );
      doc.text(
        "Thanks for supporting myHarvest Farmers. Continue to Eat Safe Food.",
        14,
        footerY - 30
      );
      doc.setFont("Mukta", "normal");
      doc.text(
        "For NEFT/IMPS: Bhoominalam myHarvest Farms Private Limited",
        14,
        footerY - 20
      );
      doc.text(
        "Account Number: 10023278921 , Account Type: Current Account",
        14,
        footerY - 15
      );
      doc.text(
        "Bank: IDFC Bank , IFSC Code: IDFB0080102, Branch: Nungambakkam",
        14,
        footerY - 10
      );

      // Add Payment details to the footer
      doc.setDrawColor(128, 128, 128); // RGB value for gray

      // Optional: Add a gray line if you need a divider above the footer
      doc.line(14, footerY - 5, 196, footerY - 5);
      doc.setTextColor(128, 128, 128); // RGB value for gray

      doc.text(
        "Together let's create Healthy Families, Happier Farmers & a Sustainable Future.",
        14,
        footerY
      );
      doc.text(Number(data?.pageNumber || 1).toString(), 190, footerY);
    },
  });

  // Add total amount
  doc.text("Rounding", 160, (doc as any).lastAutoTable.finalY + 10, {
    align: "left",
  });

  doc.text(grandTotal.toFixed(2), 192, (doc as any).lastAutoTable.finalY + 10, {
    align: "left",
  });

  doc.setFont("Mukta", "bold");
  doc.setFontSize(10);
  doc.text("Total", 160, (doc as any).lastAutoTable.finalY + 17, {
    align: "left",
  });
  doc.text(
    indian_currency_format(
      Number(total_amount?.subtotal || 0) + Number(total_amount?.gst || 0)
    ),
    180,
    (doc as any).lastAutoTable.finalY + 17,
    {
      align: "left",
    }
  );
  doc.setFont("Mukta", "normal");
  doc.text("Total In Words : ", 130, (doc as any).lastAutoTable.finalY + 25, {
    align: "left",
  });
  const amount_towards_text = doc.splitTextToSize(
    toWords
      .convert(
        Number(total_amount?.subtotal || 0) + Number(total_amount?.gst || 0)
      )
      .toString(),
    50
  );
  doc.setFont("Mukta", "bold");
  doc.text(amount_towards_text, 155, (doc as any).lastAutoTable.finalY + 25, {
    align: "left",
  });

  return doc.output("blob");
  // return doc.save("blob.pdf");
};
