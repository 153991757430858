import { combineReducers } from '@reduxjs/toolkit';
import create_farmer_order_list_iterative_reducer from './create_farmer_order_list_iterative';
import get_farmer_order_reducer from './get_farmer_order';
import get_farmer_order_list_reducer from './get_farmer_order_list';
import get_previous_demand_list_reducer from './get_previous_demand_list';

const farmer_order_reducer = combineReducers({
  create_farmer_order_list_iterative:
    create_farmer_order_list_iterative_reducer,
  get_farmer_order: get_farmer_order_reducer,
  get_farmer_order_list: get_farmer_order_list_reducer,
  get_previous_demand_list: get_previous_demand_list_reducer,
});

export default farmer_order_reducer;
