import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { RootState } from '@src/redux/store';
import * as TYPES from '@src/redux/types';

const GRAPHQL = gql`
  mutation delete_farmer($id: String!) {
    delete_farmer(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.MutationResponseState = {
  result: null,
  error: null,
  loading: false,
};

export const delete_farmer_request = createAsyncThunk<
  TYPES.MutationResponse,
  { id: String },
  { extra: ApolloClient<NormalizedCacheObject> }
>('farmer/delete_farmer', async (params: { id: String }, { extra: client }) => {
  try {
    const response = await client.mutate({
      mutation: GRAPHQL,
      variables: params,
    });
    return response.data.delete_farmer;
  } catch (error) {
    const err = error as { message?: String; status_code?: String };
    return Promise.reject({
      message: err.message || 'An error occurred',
      status_code: err.status_code || '500',
    });
  }
});

const delete_farmer_slice = createSlice({
  name: 'delete_farmer',
  initialState: initial_state,
  reducers: {
    delete_farmer_clear: state => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(delete_farmer_request.pending, state => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(delete_farmer_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(delete_farmer_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: 'Failed to fetch',
          status_code: '500',
        };
        state.loading = false;
      });
  },
});

export const { delete_farmer_clear } = delete_farmer_slice.actions;

export const delete_farmer = createSelector(
  (state: RootState) => state.farmer.delete_farmer.result,
  (state: RootState) => state.farmer.delete_farmer.error,
  (state: RootState) => state.farmer.delete_farmer.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  }),
);

export default delete_farmer_slice.reducer;
