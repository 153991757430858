import { combineReducers } from '@reduxjs/toolkit';
import create_category_reducer from './create_category';
import delete_category_reducer from './delete_category';
import get_category_reducer from './get_category';
import get_category_list_reducer from './get_category_list';
import get_category_list_for_select_reducer from './get_category_list_for_select';
import update_category_reducer from './update_category';

const category_reducer = combineReducers({
  create_category: create_category_reducer,
  delete_category: delete_category_reducer,
  get_category: get_category_reducer,
  get_category_list: get_category_list_reducer,
  get_category_list_for_select: get_category_list_for_select_reducer,
  update_category: update_category_reducer,
});

export default category_reducer;
